$(function() {
    svg4everybody();

    $(document).on('click', '.class', function(e) {
        e.preventDefault();
    });

    $(document).on('click', '.js-burger', function() {
        $(this).toggleClass('active');
        $('body').toggleClass('locked');
        $('.js-header-line').toggleClass('active');
        $('.js-header-panel').toggleClass('active');
    });

    /*
        var limit = $(window).height()/3;

    	$(window).scroll(function () {
    		if ( $(this).scrollTop() > limit ) {
    			$('.upward').fadeIn();
    		} else {
    			$('.upward').fadeOut();
    		}
    	});
    */

    $(document).on('click', '.js-up', function() {
        $('body,html').animate({scrollTop: 0}, 500);
		return false;
    });

    $(document).on('click', '.js-about-video', function(e) {
        height = $(this).find('iframe').outerHeight();
        width = $(this).find('iframe').outerWidth();

        $(this).addClass('active');

        $(this).find('.about-video-image').css('height', height);
        $(this).find('.about-video-image').css('width', width);
    });

    $(document).on('click', '.js-action-post', function(e) {
        $(this).parents('.js-about-post').toggleClass('active');
    });

	if($.fn.swiper)
	{
		new Swiper ('.promo-carousel .carousel-container', {
            slidesPerView: 1,
            pagination: '.swiper-pagination',
            paginationClickable: true,
            autoHeight: true,
            nextButton: '.promo-carousel .swiper-button-next',
            prevButton: '.promo-carousel .swiper-button-prev'
        });
		new Swiper ('.gallery-carousel .carousel-container', {
            slidesPerView: 1,
            pagination: '.swiper-pagination',
            paginationClickable: true,
            autoHeight: true
        });
        if (typeof currentTimeIndex != 'undefined') {
    		var airSwiper = new Swiper ('.air-carousel .carousel-container', {
                initialSlide: currentTimeIndex,
                slidesPerView: 4,
                nextButton: '.air-carousel .swiper-button-next',
                prevButton: '.air-carousel .swiper-button-prev',
            });
        }
    }

    if($.fn.fancybox)
	{
		$('.fancybox').fancybox({
			openEffect	: 'fadeIn',
			closeEffect	: 'fadeOut',
			fitToView	: true,
			width		: '100%',
			maxWidth	: '768',
            padding     : '0',
			autoSize	: true,
            beforeShow: function(){
                $("body").css({'overflow-y':'hidden'});
            },
            afterClose: function(){
                $("body").css({'overflow-y':'visible'});
            },
			helpers: {
				overlay: {
					locked: false
				},
			}
		});
    }
});
